@import "@vuxx/hounds/scss/index.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&family=Open+Sans:wght@400;600&display=swap');

:root {
  --color-background-danger: #d81f47;
  --color-text-sec-danger: #a20b2b;
  --color-text-primary: #22232c;
  --color-light-yellow: #ffeab9;
  --color-dark-yellow: #9c7901;
  --color-background-warning: var(--color-light-yellow);

  --color-dark-link: #3a3ac5;
  --color-light-link: #65beff;

  --color-text-link: var(--color-dark-link);
}

html, body {
  color: var(--color-text-primary);
}

.container-v1 {
  max-width: 1200px;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}

@media (prefers-color-scheme: dark) {
  .enable-dark-mode {
    --color-text-primary: #f6f6f6;
    --color-background-warning: var(--color-dark-yellow);
    --color-text-link: var(--color-light-link);
  }

  .enable-dark-mode .container-v1 {
    background-color: $color-background-dark;
  }
}
